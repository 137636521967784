.step_count .step_circle{
    background: #eee;
    height: 15px;
    width: 15px;
    border-radius: 10px;
    font-size: 10px;
    text-align: center;
    margin: 0 5px;
    opacity: 0.2;
}

.step_count .step_circle.active{
    background: #f36f21;
}

.product_icon{
    border-right: 1px solid #ccc;
}

.product_icon img{
    width: 30px;
    height: 30px;
}

.panel_options{
    position: absolute;
    right: 0;
    z-index: 9;
}

.panel_options ul li {
    font-size: 10px;
    border: 1px solid #353535;
    padding: 4px;
    margin: 5px 0;
}

.panel_options ul li.active{
    background-color: #353535;
    color: #fff;
}

.panel_close{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
}

.optionBox img{
    width: 50px;
}


.optionBox p{
    font-size: 8px;
}

.optionBox p:first-of-type{
    font-size: 13px;
}

.optionBox.active{
    border: 2px solid #f29863;
    border-radius: 5px;
}

.loading{
    position: absolute;
    background: rgb(255 255 255 / 53%);
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.size_panel{
    background-color: #f5f5f5;
    height: 20px;
    overflow: hidden;
}

.size_panel.active{
    height: 240px;
}

.size_toggle:after{ 
    content: '';
    background-color: #ccc;
    width: 20px;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.size_textfield{
    width: 45px;
    margin-right: 5px!important;
}

.size_textfield .MuiInputLabel-outlined.MuiInputLabel-marginDense{
    /* transform: translate(14px, 10px) scale(1); */
    font-size: .7rem;
}

.MuiOutlinedInput-inputMarginDense{
    padding: 10.5px!important;
    font-size: .8rem!important;
}

.btn_slab{
    padding: 2px 10px!important;
    font-size: 10px!important;
    background-color: #353535!important;
    color: #fff!important;
    display: block!important;
    margin-left: auto;
}

.best-offer tr th,
.best-offer tr td{
    font-size: 11px;
    padding: .5rem;
}

.itemList tr th,
.itemList tr td{
    font-size: 9px;
    padding: .3rem;
}

.itemList tr td input{
    font-size:0;
}   

.btn_slab:hover + .best-offer{
    display: block;
}

.best-offer{
    position: absolute;
    width: 100%;
    top: 12px;
    z-index: 99;
    display: none;
}

.total_qty_details{
    background-color: #eee;
    padding: 1rem;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
}

.brandTypeTab .MuiTab-wrapper{
    flex-direction: row;
}

.brandTypeTab .MuiTab-wrapper > *:first-child{
    margin-bottom: 0!important;
    margin-right: 5px;
}

.brandingTypeOptions{
    background: #eee;
    position: absolute;
    width: 100%;
    padding: .5rem;
    display: flex;
    justify-content: space-around;
}

.brandingTypeOptions button{
    font-size: 11px!important;
    padding: 0!important;
}

.brandingTabPanelLogo,
.brandingTabPanelText{
    position: absolute;
    background: #fff;
    width: 100%;
    height: calc(100% - 110px);
    z-index: 9;
    top: 110px;
}

.logo_chip{
    width: 75%;
    margin: 10px auto ;
    height: 150px;
    background-position: center;
    background-size: 400px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc;
}

.logo_chip img{
  width: 100%;
  height: 150px;
  object-fit: contain;
  padding: 10px;
}

.bd_option img{
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.bd_option span{
    font-size: 10px;
}

.copyrightText input{
    font-size: 0px;
    width: 35px;
    margin-right: 5px;
}

.copyrightText p{
    font-size: 10px;
}

.logoBox img{
    
    height: 50px;
    object-fit: contain;
}
.logoBox > span{
    
    height: 50px;
    margin-right: 10px;
}

.logo_panel{
    height: 200px;
    overflow-y: scroll;
}

.selection_tab.MuiTab-labelIcon,
.tabParent{
    min-height: 40px!important;
}   

.selection_tab .MuiTab-wrapper{
    flex-direction: row;
}

.selection_tab .MuiTab-wrapper img{
    width: 25px;
    margin-right: 7px;
    height: 20px;
    object-fit: contain;
    margin-bottom: 0!important;
}

.tabParent .selection_tab{
    border-right: 1px solid #ccc;
    width: 140px;
}

.tabParent .selection_tab.active{
    background-color: rgb(243 111 33 / 5%)!important;
}

.tabParent .selection_tab:last-child{
    border-right: none;
}

.customise_panel h6 img{
    width: 20px;
    height: 15px;
    object-fit: contain;
    margin-right: 5px;
}

.chooseOptions{
    display: flex;
    width: 75%;
    margin: auto;
    align-items: center;
}

.chooseOptions label{
    font-size: 11px;
    width: 110px;
    margin-bottom: 0;
}

.logo_image{
    position: absolute;
    object-fit: contain;
}
   
.front_logo{
    right: 32%;
    width: 41px;
    top: 32%;
    height: 25px;
}

.front_logo_f{
    width: 41px;
    top: 31%;
    height: 25px;
    right: 30%;
}

.back_logo{
    top: 19%;
    width: 30px;
    left: 0;
    right: 0;
    margin: auto;
    height: 25px;

}

.back_logo_f{
    width: 30px;
    height: 25px;
    margin: auto;
    left: 0;
    right: 0;
    top: 18%;
}

.right_logo{
    top: 34%;
    width: 35px;
    right: 47%;
    transform: rotate(-15deg);
    height: 25px;
}

.right_logo_f{
    top: 36%;
    width: 35px;
    right: 53%;
    transform: rotate(-15deg);
    height: 25px;
}

.left_logo{
    width: 35px;
    top: 33%;
    right: 43%;
    transform: rotate(16deg);
    height: 26px;
}

.left_logo_f{
    width: 35px;
    top: 36%;
    right: 38%;
    transform: rotate(16deg);
    height: 26px;
}

.front_zoom_logo{
    right: 50%;
    top: 50%;
    width: 95px;
    height: 50px;
}

.front_zoom_logo_f{
    right: 45%;
    top: 45%;
    width: 95px;
    height: 50px;
}

.back_zoom_logo{
    top: 38%;
    right: 0;
    width: 100px;
    height: 60px;
    left: 0;
    margin: auto;
}

.back_zoom_logo_f{
    top: 40%;
    right: 0;
    width: 100px;
    height: 60px;
    left: 0;
    margin: auto;

}

.left_zoom_logo{
    width: 90px;
    top: 55%;
    right: 35%;
    transform: rotate(12deg);
    height: 50px;
}

.left_zoom_logo_f{
    width: 90px;
    top: 63%;
    right: 23%;
    transform: rotate(12deg);
    height: 50px;
}

.right_zoom_logo{
    width: 90px;
    top: 56%;
    right: 42%;
    transform: rotate(-13deg);
    height: 50px;
}

.right_zoom_logo_f{
    width: 90px;
    top: 63%;
    right: 51%;
    transform: rotate(-12deg);
    height: 50px;
}


/* Cap Css */
.cap_zoom_logo_s1{
    top: 36%;
    margin: auto;
    left: 0;
    right: 0;
    width: 115px;
    height: 70px;   
}

.cap_zoom_logo_s2{
    top: 41%;
    margin: auto;
    left: 0;
    right: 0;
    width: 115px;
    height: 70px;  
}
    
.cap_logo_s1{
    top: 38%;
    left: 0;
    right: 0;
    margin: auto;
    width: 86px;
    height: 49px;
}
  
  
.cap_logo_s2{
    top: 41%;
    left: 0;
    right: 0;
    margin: auto;
    width: 86px;
    height: 49px;
}
    
.cap_back_logo_s2{
    top: 43%;
    left: 0;
    margin: auto;
    right: 0;
    width: 58px;
    height: 60px;
}

.cap_back_logo_s1{
    top: 41%;
    left: 0;
    margin: auto;
    right: 0;
    width: 58px;
    height: 60px;
}
    
.cap_back_zoom_logo_s2{
    top: 38%;
    margin: auto;
    left: 0;
    right: 0;
    width: 105px;
    height: 100px;
}

.cap_back_zoom_logo_s1{
    top: 22%;
    margin: auto;
    left: 0;
    right: 0;
    width: 105px;
    height: 100px;
}
    
.cap_right_logo_s2{
    top: 52%;
    left: -30%;
    right: 7%;
    margin: auto;
    width: 75px;
    transform: rotate(-10deg);
    height: 51px;
}

.cap_right_logo_s1{
    top: 50%;
    left: -30%;
    right: 7%;
    margin: auto;
    width: 60px;
    
    transform: rotate(-10deg);
    height: 38px;
}

.cap_right_zoom_logo_s2{
    top: 52%;
    left: -5px;
    right: 0;
    margin: auto;
    width: 140px;
    transform: rotate(-9.5deg);
    height: 75px;
}

.cap_right_zoom_logo_s1{
    top: 42%;
    left: -5px;
    right: 0;
    margin: auto;
    width: 106px;
    transform: rotate(-10.5deg);
    height: 75px;
}
    
.cap_left_logo_s2{
    top: 52%;
    left: 36%;
    right: 0;
    margin: auto;
    transform: rotate(11deg);
    width: 80px;
    height: 49px;   
}

.cap_left_logo_s1{
    top: 50%;
    left: 36%;
    right: 0;
    margin: auto;
    transform: rotate(11deg);
    width: 60px;
    height: 38px;      
}
    
.cap_left_zoom_logo_s2{   
    top: 51%;
    left: 19px;
    right: 0;
    margin: auto;
    width: 140px;
    transform: rotate(10.5deg);
    height: 75px;
}

.cap_left_zoom_logo_s1{   
    top: 39%;
    left: -5px;
    right: 0;
    margin: auto;
    width: 106px;
    transform: rotate(10.5deg);
    height: 75px;
}

.logoDesc li{
    color: red;
    font-size: 9px;
    text-align: center;
}

.backToHome{
    display: inline-block;
    padding: 5px 10px;
    border-right: 1px solid #ccc;
}

.tooltip_mob + .MuiTooltip-popper > .MuiTooltip-touch{
    font-size: 9px!important;
}

.commentDel {
    font-size: 11px;
    width: 20px;
    height: 10px;
}

@-webkit-keyframes swinging{
    0%{-webkit-transform: rotate(10deg);}
    50%{-webkit-transform: rotate(-5deg)}
    100%{-webkit-transform: rotate(10deg);}
}
 
@keyframes swinging{
    0%{transform: rotate(10deg);}
    50%{transform: rotate(-5deg)}
    100%{transform: rotate(10deg);}
}
 
.swingimage{
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-animation: swinging 3.5s ease-in-out forwards infinite;
    animation: swinging 3.5s ease-in-out forwards infinite;
}