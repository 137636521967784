@font-face {
  font-family: "Impact";
  src: url("./assets/fonts/impact.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/impact.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/impact.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/impact.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/impact.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/impact.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/impact.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Verdana";
  src: url("./assets/fonts/verdana.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/verdana.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/verdana.otf") format("opentype"), /* Open Type Font */
    url("./assets/fonts/verdana.svg") format("svg"), /* Legacy iOS */
    url("./assets/fonts/Verdana.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./assets/fonts/verdana.woff") format("woff"), /* Modern Browsers */
    url("./assets/fonts/verdana.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}


html,body {
  margin: 0;
  height: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Verdana', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
