@import './configurator.css';


:root{
    --theme: #f36f21;
}

.mainScreen{
    width: 100%!important;
    margin: auto;
}

button{
  outline: none!important;
}

a{
    color: var(--theme)!important;
    text-decoration: none;
}

.logo{
  width: 200px;
}

.login_panel .login_logo img{
    width: 150px;
    margin-bottom: 10px;
    margin-left: -20px;
    height: 48px;
}

.logo_header:before{
    content: '';
    position: absolute;
    background: var(--theme);
    width: 2px;
    height: 100%;
    left: -15px;
}

.logo_header h4{
    font-size: 19px;
    margin:0;
    font-weight: 400;
}

 .logo_header p{
    margin:0;
    font-size: 13px;
}   

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none!important;
}

 .logo_header a{
    text-decoration: none;
    color: var(--theme);
}

.login_labels{
    font-size: 16px!important;
}

.btn-theme-outlined{
    border: 1px solid var(--theme)!important;
    background-color: transparent!important;
    color: var(--theme)!important;
}

.btn-theme-outlined-animate{
  border: 1px solid var(--theme)!important;
  background-color: transparent!important;
  color: var(--theme)!important;
  border-radius: 20px!important;
  transition: all .5s;
}

.btn-theme-outlined-animate:hover{
  border: 1px solid var(--theme)!important;
  background-color: var(--theme)!important;
  color: #fff!important;
}


.btn-theme-contained{
    background-color: var(--theme)!important;
    color: #fff!important;
}

.divider{
    background-color: #fff;
    z-index: 9;
}

.divider{
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #ccc; 
    line-height: 0.1em;
    margin: 30px 0 30px; 
}

.divider span{
    background:#fff; 
    padding:0 10px; 
}


.google_btn{
    background: transparent!important;
    color: #000!important;
    border: 1px solid #ccc!important;
    border-radius: 20px!important;
    width: 75%;
}

.google_btn img{
    width: 20px;
    margin-right: 10px;
}

.facebook_btn{
    background: #3b5998!important;
    color: #fff!important;
    border: 1px solid #3b5998!important;
    border-radius: 20px!important;
    width: 75%;
}

.facebook_btn img{
    width: 20px;
    margin-right: 10px;
}


.font_9vw{
    font-size: .9vw!important;
}

.font_13{
  font-size: 13px!important;
}

.font_14{
  font-size: 14px!important;
}
.font_12{
  font-size: 12px!important;
}
.font_11{
  font-size: 11px!important;
}

.font_10{
  font-size: 10px!important;
}


.font_1vw{
    font-size: 1vw!important;
}

.font_1_5vw{
    font-size: 1.5vw!important;
}

.logged .MuiFormControlLabel-label{
    font-size: 13px;
}

/* .login-dialog .MuiDialog-container .Mui-Dialog-paper{
    max-width: 450px;
} */

.validation_text{
    font-size: .9vw;
    margin-top: 10px;
}

.login-dialog .MuiDialog-container .MuiDialog-paper{
    width: 550px!important;
}


.otp_content input{
    width: 2em!important;
    text-align: center!important;
    height: 3em;
    border-radius: 15px;
    font-size: 1vw;
    outline: 0!important;
}

.forgotpassword_panel{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot_box{
    background: #f5f5f5;
    padding: 2rem;
    border-radius: 10px;
}

/* Homepage Css */

 
  h1, h2{font-family: 'Impact', sans-serif; margin:0px; padding:0px;}
  
  .slide .text{
    position: absolute;
    left: -75px;
    right: 0;
    margin: auto;
    width: 307px;
    top: 245px;
  }
  .slide .text h4{ color: #555;font-size: 34px;font-family: 'Impact', sans-serif; margin:0px; padding:0px;}
  .slide .text h2{
        font-family: 'Impact', sans-serif; margin:0px; padding:0px;
      -webkit-text-stroke: 1px #fff;
      -webkit-text-fill-color: transparent;
      font-size: 60px;
      color: #555;
  }
  .clr{ clear:both;}
  .toppad1{ padding-top:10px;}
  .toppad2{ padding-top:15px;}
  .toppad3{ padding-top:20px;}
  .toppad4{ padding-top:25px;}
  .toppad5{ padding-top:30px;}
  .toppad6{ padding-top:35px;}
  .toppad7{ padding-top:40px;}
  .toppad8{ padding-top:45px;}
  .toppad9{ padding-top:50px;}
  .toppad10{ padding-top:55px;}
  .toppad11{ padding-top:60px;}
  .toppad12{ padding-top:65px;}
  .toppad13{ padding-top:70px;}
  .toppad14{ padding-top:80px;}
  .toppad15{ padding-top:90px;}
  .topmenu{ background:#ededed; text-align:center; font-size:14px; padding:5px 0;}
  .lrpadd{padding:0px;}
  .lrpadd60{padding:0px 60px;}
  section{display: block; overflow: hidden;}
  .maincon{ width:75%; margin: 0 auto;}
  .maincontainer{ width:1010px; margin: 0 auto;}
  /* .logo{width: 100%;} */
  .affix { top: 0; width: 100%; z-index: 9999 !important;}
  .navbar {
      border: 0;
      border-radius: 3px;
      padding: .625rem 0;
      margin-bottom: 20px;
      color: #555;
      background-color: #fff!important;
      /* box-shadow: 0 4px 18px 0 rgba(0,0,0,.12), 0 7px 10px -5px rgba(0,0,0,.15) !important; */
      z-index: 1000 !important;
      transition: all 150ms ease 0s;
      height: 85px;
  }
  
  .navbar.navbar-transparent {
      z-index: 1030;
      background-color: transparent!important;
      box-shadow: none !important;
      padding-top: 25px;
      color: #fff;
  }
  
  .navbar .navbar-brand {
      position: relative;
      color: inherit;
      
      font-size: 1.125rem;
      line-height: 30px;
      padding: .625rem 0;
      font-weight: 300;
      -webkit-font-smoothing: antialiased;
  }
  
  .navbar .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
      margin-top: -7px;
      top: 3px;
      position: relative;
      margin-right: 3px;
  }
  
  .navbar .navbar-nav .nav-item .nav-link .material-icons {
      font-size: 1.25rem;
      max-width: 24px;
      margin-top: -1.1em;
  }
  
  .navbar .navbar-nav .nav-item .nav-link .fa, .navbar .navbar-nav .nav-item .nav-link .material-icons {
      font-size: 1.25rem;
      max-width: 24px;
      margin-top: -1.1em;
  }
  
  .navbar .navbar-nav .nav-item .nav-link {
      position: relative;
      color:#000!important;
      padding: .3375rem 1.4rem;
      font-weight: 400;
      font-size: 14px;
      border-radius: 3px;
      line-height: 20px;
  }

 .custom_drop .dropdown-toggle::after{
  margin-left: .855em!important;
  vertical-align: .155em!important;
  }
  
  a .material-icons {
      vertical-align: middle;
  }
  
  .fixed-top {
      position: fixed;
      z-index: 1030;
      left: 0;
      right: 0;
  }


  
  .linehead{ width:100%; }
  .lineheadleft{width:45%; float:left; height: 5px; position:relative;}
  .lineheadleft1{width:34%; background:#ee6a23; height:5px; float:left; position: absolute; }
  .lineheadleft2{width:66.7%; background:#737373; height:1px; float:left; position: absolute; top: 2px; left: 202px; z-index: -1;}
  .lineheadright{width:50%; float:left; position:relative;margin-top: -5px;}
  .lineheadright h1{font-size:37px; letter-spacing: 2px; color:#cccbc9;}
  .lineheadright h2{ position:absolute; top:8px; left:12px; font-size:37px; letter-spacing: 2px; color:rgb(237 237 237 / 60%); z-index:-1;}
  .panelbg{ width: 100%; padding:20px 0; float: left; margin-top: 180px;}
  .panelinner{ width: 100%; background: #e8e8e8; border-radius:0px; padding:20px 0; float: left; height:306px;}
  /* .panelleft{ position: relative;} */
  .panelleft { 
      margin-top: -43%;
  }
 
  .panelmiddile h2{color:#737373; letter-spacing: 1px; width: 80%; font-size: 60px;margin-left: -8%;}
  .panelmiddile h2::before{
      content: 'P';
     -webkit-text-stroke: 1px #fff;
     -webkit-text-fill-color: transparent;
  }
 
  .panelmiddile h3{font-size:19px; color:#231f20;}
  .panelmiddile a{
      margin-left: 35%;
  }
  .panelmiddile1 a{
    margin-left: 6%;
}
.panelmiddile2 a{
  margin-left: 35%;
}

  
.cta {
  position: relative;
    margin: auto;
    padding: 16px 22px;
    transition: all 0.2s ease;
    display: inline-block;
    text-decoration: none!important;
}


.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: rgba(255, 171, 157, 0.5);
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  vertical-align: middle;
}
.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #111;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.Continue-shopping{
  opacity: 0.3; 
  text-decoration: none !important;
}
.Continue-shopping:hover{
  color: #000;
  opacity: 1 !important;
  text-decoration: none !important;
}

.cta:hover:before {
  width: 100%;
  background: #ffab9d;
}
.cta:hover svg {
  transform: translateX(0);
}
.cta:active {
  transform: scale(0.96);
}

  .panelright{ 
      margin-top: -59px;
      text-align: right;
   }

  .panelright img{ 
    width:auto;
    height: 345px;
  }

  .panelbg1{ width: 100%; padding:20px 0; float: left; margin-top: 150px;}
  .panelinner1{ width: 100%; background: #e8e8e8; border-radius:0px; padding:20px 0; float: left; height:306px;}
  /* .panelleft{ position: relative;} */
  .panelleft1 { 
      margin-top: -36%;
  }
 
  .panelmiddile1 h2{color:#737373; letter-spacing: 1px; width: 100%; font-size: 60px;transform: translate(28%);}
  .panelmiddile1 h2::after{
      content: 'P';
     -webkit-text-stroke: 1px #fff;
     -webkit-text-fill-color: transparent;
  }
 .color-red-text{
   color:red;
 }
  .panelmiddile1 h3{font-size:19px; color:#231f20;}
  /* .panelmiddile1 a{
      margin-left: 6%;
      background-image: url(../images/button.png);
    border-radius: 15px;
    position: relative;
    z-index: 99;
    background-size: 175px;
    background-repeat: no-repeat;
  } */

  .panelright1{ 
      margin-top: -59px;
   }

  .panelright1 img{ 
    width: auto;
    height: 345px;
  }
  .panelbg2{ width: 100%; padding:20px 0; float: left; margin-top: 185px;}
  .panelinner2{ width: 100%; background: #e8e8e8; border-radius:0px; padding:20px 0; float: left; height:306px;}
  /* .panelleft{ position: relative;} */
  .panelleft2 { 
      margin-top: -44%;
  }
 
  .panelmiddile2 h2{color:#737373; letter-spacing: 1px; width: 100%; font-size: 60px;transform: translate(-19%);}
  .panelmiddile2 h2::before{
      content: '6';
     -webkit-text-stroke: 1px #fff;
     -webkit-text-fill-color: transparent;
  }
 
  .panelmiddile2 h3{font-size:19px; color:#231f20;}
  /* .panelmiddile2 a{
      margin-left: 35%;
      background-image: url(../images/button.png);
    border-radius: 15px;
    position: relative;
    z-index: 99;
    background-size: 175px;
    background-repeat: no-repeat;
  } */

  .panelright2{ 
      margin-top: -20%;
      text-align: right;
   }

  .panelright2 img{ 
    width: auto;
    height: 343px;
  }

  .profile_options{
    border-top: 3px solid #EC6A23;
  }
  /* .panelbg1{ width: 100%; padding:20px 0; float: left; margin-top: 150px;}
  .panelinner1{ width: 100%; background: #e8e8e8; border-radius:0px; padding:20px 0; float: left; height:306px;}
  .panelleft1{ position: relative;}
  .panelleft1 img{ position: absolute; top: -181px; right: 0px;}
  .panelmiddile1{ position: relative;}
  .panelmiddile1 h2{position: absolute; top:9px; right: -191px; font-size:60px; width: 80%; z-index:1; }
  .panelmiddile1 h2 span{position: absolute; top: 18px; font-size:44px; color:#737373; letter-spacing: 1px;}
  .panelmiddile1 h3{position: absolute; top:60px; font-size:19px; color:#231f20;}
  .panelmiddile1 a{position: absolute; top:150px}
  .panelright1{ position: relative; }
  .panelright1 img{ position: absolute; top: -86px;}
   */
  .introofferbx{ width:100%; float:left;}
  .introofferbxin{ background:#e9e8e4; width:100%; text-align:center;}
  .introofferbxin1{width:50%; float:left; }
  /* .introofferbxin2{width:22%;margin: auto;} */
  /* .introofferbxin3{width:24%; float:left;}  */
  .introofferbxin3up{width:100%; float:left;background-color: #ebeae6;}
  .introofferbxin3down{width:100%; background: #eea789; float:left; padding:10px; text-align:center;height: 180px;}
  .introofferbxin3down h2{ color:#03122f; font-size:24px;}
  .introofferbxin3down p{ color:#fff; font-size:16px; font-weight:200; padding: 0px 0 0 0;}
  .introofferbxin4{width:27%; float:left; }
  .introofferbximg img{width:100%;}
  .introofferblue{ width:100%; background:#071738;display: flex;align-items: center;justify-content: center;}
  .introofferblue img{ padding:100px 76px;}

  /* .unlockTreasure{
    background-image: url(../images/offer_back.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  } */
  .text-theme-color{
    color: #f36f21!important;   
  }

  .text-theme-low-opacity{
    color: rgb(243 111 33 / 15%)!important;
  }
  
  .abtusbx{ padding:50px 0;}
  .abtusbx p{ font-size:15px;}
  .abtusbx a,.low-opacity{opacity: 0.4;}
  
  .clientbx{ background:#e1e1e1; width:100%; float:left; padding:65px 0; position:relative;}
  
  .ftr{ background:#2c3033; color:#fff; font-size:12px; padding:20px 0 0 0; position:relative;}
  .ftr1{width:20%; float:left;}
  .ftr2{width:36%; float:left;}
  .ftr ul{ margin:0px; padding:10px 0 0 0;}
  .ftr ul li{list-style:none; padding-bottom:12px;}
  .ftr ul li button,.ftr ul li a{color:#fff!important;letter-spacing: 0.5px;}
  .ftr ul li a img{width: 25px;}
  .ftr ul li button:hover,.ftr ul li a:hover{color:#ff6f1b}

  
  .arrow {
    border: solid #ff6f1b;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px; margin-right: 10px;
  }

  .img-logo{
    width: 200px!important;
    height: 120px!important;
    object-fit: contain;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .ftrbx{ width:100%; padding:0px 40px 0px 40px; margin-top:50px; border-top:solid 1px #787c7f; float:left; color:#b1b2b6;}
  .ftrbx ul{ margin:0px; padding:10px 0 0 0; text-align: right;}
  .ftrbx ul li{list-style:none; display: inline-block;}
  .ftrbx ul li a{color:#ff6f1b!important;}
  .ftrbx ul li a:hover{color:#ff6f1b;}
  
  a{color:#ff6f1b;}
  a:hover{color:#ff6f1b;}
  
  /* form starting stylings ------------------------------- */
  .group{ position:relative; width: 250px; float: left; margin-bottom:45px; }
  .sbmt{ width: 98px; float: left;}
  .sbmtbtn{ background:transparent; border:1px solid #ff6f1b; border-radius:40px; color:#ff6f1b; padding:4px 0; width: 100%;}
  .subs input{
    font-size:14px; background: transparent;
    padding:0px 10px 8px 5px;
    display:block;
    width:220px;
    border:none;
    border-bottom:1px solid #787c7f;
  }
  .subs input:focus { outline:none; }
  .subs label{
    color:#fff; 
    font-size:14px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .subs input:focus ~ .subs label, .subs input:valid ~ .subs label {
    top:-20px;
    font-size:14px;
    color:#fff;
  }
  .bar { position:relative; display:block; width:250px; }
  .bar:before, .bar:after {
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:#ff6f1b; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .bar:before {
    left:50%;
  }
  .bar:after {
    right:50%; 
  }
  
  .subs input:focus ~ .bar:before, .subs input:focus ~ .bar:after { width:50%;}
  
  .highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
  
  .subs input:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  
  @-webkit-keyframes inputHighlighter {
      from { background:#5264AE; }
    to { width:0; background:transparent; }
  }
  @-moz-keyframes inputHighlighter {
      from { background:#5264AE; }
    to { width:0; background:transparent; }
  }
  @keyframes inputHighlighter {
      from { background:#5264AE; }
    to { width:0; background:transparent; }
  }
  
  .sidesocial{ position:absolute; right:0px; top:-10px;}
  .sidesocialimg{padding-right:5px; padding-bottom:10px;height: 100px;}
  .sidesocial ul li{padding-bottom:5px;}
  
  .header_strip p{
    margin: 0;
    font-size: 13px;
    color: #ccc;
    text-align: center; 
  }

  .header_strip{
    background: #f5f5f5;
    width: 100%;
    padding: .3rem;
    position: relative;
    z-index: 99;
  }

  .navbar-text ul li a{padding: 0 10px;}
  
  

.banner{
    background-image: url(../images/banner-background.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 600px;
    position: relative;
}

.banner .circle{
    position: absolute;
    bottom: 12%;
    left: 10%;
    /* -webkit-animation:spin 9s linear infinite;
    -moz-animation:spin 9s linear infinite;
    animation:spin 9s linear infinite; */
}

.feature ul li {
  width: 33%;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.banner .slide{
    width: 100%;
    position: relative;
    -webkit-transition: width .5s; /* For Safari 3.1 to 6.0 */
    transition: width .5s;
}

.banner .slideImg{
  overflow: hidden;
  width: 0;
  transition: all .9s;
}



.banner .slide .text{
  opacity: 0;
  transition: all .5s;
}

.banner .slide.active .text{
  opacity: 1;
  transition: all .5s;
}

.banner .slide:first-child.active .slideImg:first-child{
  width: 351px;
}
.banner .slide:first-child.active .slideImg:last-child{
  width: 303px;
}

.banner .slide:last-child.active .slideImg:first-child{
  width: 304px;
}
.banner .slide:last-child.active .slideImg:last-child{
  width: 245px;
}

.banner .slide:first-child .slideImg:first-child img{
  width: 355px;
}
.banner .slide:first-child .slideImg:last-child img{
  width: 332px;
}

.banner .slide:last-child .slideImg:first-child img{
  width: 243px;
}
.banner .slide:last-child .slideImg:last-child img{
  width: 264px;
}
  
.banner .slide .slideImg:first-child{
    position: absolute;
    left: 11%;
    top: 30px;
}

.banner .slide .slideImg:last-child{
    position: absolute;
    right: 213px;
    top: 49px;
}

 .slideIndex{
  position: absolute;
    right: 10%;
    top: 36%;
    bottom: 0;
    margin: auto;
    color: #fff;
    width: 7px;
 }

.banner .dots-container{
    width: 4px;
    height: 100px;
    background: #fff;
    position: absolute;
    right: 10%;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
}

.banner .dots-container:after{
  content:'';
  background-image: url(../images/arrow_down.png);
  background-size: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
}

.banner .dots{
    height: 50px;
    width: 4px;
    display: inline-block;
    cursor: pointer;
}

.banner .dots.active{
    background: #ed6a24;
}

.extra-icons{
    transition: all .2s;
    font-size: 18px;
    cursor: pointer;
}
.cursor-pointer{
  cursor: pointer !important;
}


/* .owl-carousel .owl-item img{
    width: 150px;
} */

.breadcrumbs {
    padding: 10px 0;
    background-color: #f5f5f5;
    min-height: 40px;
    margin-top: 0px;text-align:right;
  }
 
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 0px;
    }

    .logo{
      width: 170px;
    }
    .breadcrumbs h2 {
      margin: 0 0 10px 0;
    }
  }

  @media screen and (min-width:991px){
    .sizeInput{
      margin: 5px!important;
    }

    .sizeInput input{
      padding: 12.5px 8px!important;
      font-size: 11px!important;
    }

    .step_count span{
      margin-bottom: 0;
    }

    
  }
  
  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
  }

  
  
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;float:right;
    font-size: 14px;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
    .step_image h3{
      font-size: 20px;
      margin-top: 30px;
    }
    
    .step_image p {
      font-size: 13px;
    }

    .step_count::before{
      content: '';
      position: absolute;
      bottom: -100%;
      width: 2px;
      height: 150px;
      background-color: #000;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(90deg)!important;
    }
    
    .howitworks_main .step_sections_.firstChild .step_count::before{
      content: none!important;
    }
  }

.titlelogo{
  position: absolute;
    top: -25%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.left_side .logo_header h4{
  font-size:18px;
}

.order-container .MuiOutlinedInput-input{
  padding: 8.5px 8px;
}

.profileImg{
  width: 150px;
  height: 150px;
  position: relative;
  border: 3px dashed #ccc;
  border-radius: 50%;
   
}

.profileImg > img{
  border-radius: 50%;
}

.profileImg label{
  position: absolute;
    bottom: -14px;
    right: -7px;
}


.profile_container:after{
  content: '';
  position: absolute;
  width: 22%;
  height: 100%;
  right: 0;
  bottom: 0;
  background-image: url(../images/profile-right.png);
  background-size: 100%;
  background-repeat: no-repeat;
  top: auto;
}

.left_side ul li{
  width: 72px;
  height: 72px;
  border: 2px solid #eee;
  margin-bottom: 10px;
  cursor: pointer;
}

.left_side ul li.active{
  border: 2px solid rgb(236 106 35 / 44%);
}

.left_side ul li img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gender.active{
  color: #EC6A23!important;
}
.theme-color-a{
  color: #EC6A23!important;
}

.custom_drop .dropdown-menu{
  top: 55px!important;
  border: none!important;
  border-radius: 0!important;
  box-shadow: 0 1.6rem 1rem rgba(0,0,0,.15)!important;
}

.product_drop .dropdown-menu{
  min-width: 25rem!important;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: transparent!important;
}

.custom_drop .dropdown-menu .dropdown-item{
  font-size: 12px;
  padding: .25rem 10px;
  color: #888;
}

.dropdown.show a{
  background-color: #eee;
  border-radius: 20px!important;
}

.custom_drop .dropdown-menu .dropdown-item img{
  width: 18px;
}

.custom_drop .dropTitle{
  padding: 2px 10px;
  /* text-align: center; */
  font-size: 14px;
  border-bottom: 1px solid #888;
}
/* 
.table>tbody>tr>td, .table>tfoot>tr>td{
  vertical-align: top;
} */

.table#cart thead th{
  font-size: 14px;
  font-weight: 400;
}

@media screen and (min-width: 1348px){
  .mainScreen{
    width: 1348px!important;
  }
}

@media screen and (max-width: 600px) {
  .d-small-flex{
    display: flex;
  }
  .justify-content-small-center{
      justify-content: center;
  }
  table#cart tbody td .form-control{
  width:20%;
  display: inline !important;
}
.actions .btn{
  width:36%;
  margin:1.5em 0;
}

.actions .btn-info{
  float:left;
}
.actions .btn-danger{
  float:right;
}

table#cart thead { display: none; }
table#cart tbody td { display: block; padding: .6rem; min-width:320px;}
/* table#cart tbody tr td:first-child { background: #333; color: #fff; } */



table#cart tfoot td{display:block; }
table#cart tfoot td .btn{display:block;}

}



@media only screen and (max-width: 600px) {

  .login_panel .login_logo img{
    margin-left:0;
  }

  .google_btn,.facebook_btn{
    width: 100%;
  }

  .panelleft,
  .panelleft2{
    margin-top: -67%;
    width: 190px;
  }

  .panelleft2{
    margin-top: -77%;
    width: 190px;
  }
  
  .panelmiddile h2,
  .panelinner2 h2{
    margin-left: 13%;
    font-size: 23px;
    margin-bottom: 0;
  }

  .panelinner2 h2{
    margin-left: 24%;
  }

  .panelmiddile h3,
  .panelmiddile2 h3{
    font-size: 8px;
  }

  .panelmiddile button,
  .panelmiddile1 button,
  .panelmiddile2 button {
    font-size: 10px;
  }

  .panelinner,
  .panelinner1,
  .panelinner2 {
    height: 106px;
  }

  .panelmiddile1 h2{
    font-size: 23px;
    margin-bottom: 0;
    transform: translate(17%);
  }

  .panelmiddile1 h3{
    font-size: 8px;
  }

  .panelbg1,
  .panelbg2{
    margin-top: 60px;
  }

  .unlockTreasure{
    height: 300px;
  }

  .panelleft1 {
    margin-top: -48%;
    width: 190px;
  }

  .profile_container:after{
    content: none;
  }

  .ftr1{
    width: 50%;
  }

  .ftr1{
    float: none;
  }

  .ftr2{
    width: 100%;
  }

  .group{
    width: 230px;
  }

  .abtusbx p{
    font-size: 10px;
  }

  .lrpadd60 .ftr1{
    border-bottom: 1px solid #eee;
  }

  .lrpadd60 .ftr1:last-of-type{
    border-bottom: none!important;
  }

  .lineheadleft{
    width: 25%;
  }

  .lineheadleft .lineheadleft1{
    width: 50%;
  }
  .lineheadleft .lineheadleft2{
   left: 33px;
  }

  .lineheadright{
    width: 70%;
  }

  .lineheadright h1{
    font-size: 20px;
  }
  .lineheadright h2{
    font-size: 20px;
    top: 3px;
    left: 3px;
  }

  .panelbg{
    margin-top: 60px;
  }

  .banner .slide .slideImg:first-child {
    position: absolute;
    left: 2%;
    top: 22px;
  }

  .banner .slide .slideImg:last-child {
    position: absolute;
    right: 0;
    top: 16px;
  }

  .banner .slideImg img{
    width: 0!important;
  }

  .banner .slide:first-child.active .slideImg:first-child,
  .banner .slide:first-child.active .slideImg:first-child > img,
  .banner .slide:first-child.active .slideImg:last-child,
  .banner .slide:first-child.active .slideImg:last-child > img{
    width: 136px!important;
  }

  .banner .slide:last-child.active .slideImg:first-child,
  .banner .slide:last-child.active .slideImg:first-child > img{
    width: 93px!important;
  }

  .banner .slide:last-child.active .slideImg:last-child,
  .banner .slide:last-child.active .slideImg:last-child > img{
    width: 108px!important;
  }

  .banner .slide:last-child .slideImg:first-child{
    top: 24px;
  }
  .banner .slide:last-child .slideImg:last-child{
    top: 17px;
  }

  .banner .slide:first-child .text{
    left: 33%;
    top:100px
  }
  
  .slide .text h4{
    font-size: 22px;
  }

  .slide .text h2{
    font-size: 25px;
  }

  .banner .slide:last-child .text{
    left: 37%;
    top:100px
  }

  .dots-container{
    display: none;
  }
  .slideIndex{
    display: none;
  }

  .banner .circle {
    position: absolute;
    bottom: 8%;
    left: 3%;
    width: 45px;
  }

  .mainScreen .banner{
    height: 230px;
    background-size: cover;
  }

  .ftrbx span{
    font-size: 5px;
  }

  .ftrbx .flag{
    width: 17px;
  }

  .ftrbx ul li a{
    font-size: 10px;
  }

  .ftrbx ul{
    text-align: center;
  }

  .unlockTreasure{
    display: none;
  }

  .knowMoreSec{
    display: none!important;
  }

  .abtusbx{
    display: none!important;
  }

  .owl-carousel .owl-stage {
    transition-timing-function: linear !important;
  }

  .forgot_logo img{
    width: 200px;
    margin: 0 auto 10px
  }

  .forgot_box .MuiTextField-root .MuiFormLabel-root {
    font-size: 13px!important;
  }

  .forgot_box .MuiTextField-root .MuiInput-input{
    font-size: 13px!important;
  }

  .sizeInput input{
    padding: 12.5px 5px!important;
  }

  .sizeInput{
    margin: 2px!important;
  }

  .sizeInput input{
    padding: 12.5px 5px!important;
    font-size: 11px!important;
  }
  .sizeInput .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(10px, 12px) scale(1);
  }
  .sizeInput .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }



  #cart tr td h6{
    font-size: 18px;
  }

  .cancelContainer img{
    height: 280px!important;
  }

  .orderDetails{
    bottom: 7%!important;
    width: 75%!important;
  }

  .best-offer-cart{
    width: 100%!important;;
  }
}

.best-offer-cart{
  width: 50%!important;
}

.size_collapse{
  box-shadow: none!important;
  margin: 0!important;
}

.size_collapse .MuiAccordionSummary-content{
  margin: 5px 0;
}

.size_collapse .MuiAccordionSummary-content p{
  font-size: 13px;
}

.size_collapse .MuiAccordionSummary-root{
  min-height: 20px!important;
}

.size_collapse .MuiAccordionSummary-expandIcon{
  padding: 0 12px;
}

.size_collapse .MuiAccordionSummary-content.Mui-expanded{
  margin: 0;
}
.custom_drop .MuiChip-root {
  background-color:#EC6A23;
  color: #fff;
  cursor: pointer;
  width: 120px;
  justify-content: start;
}

.custom_drop .MuiChip-root:hover{
  background-color: #EC6A23;
  color:#fff;
}

.content.activeContainer{
  max-height: 100%!important;
  padding: 8px 18px;
}



.cartImage{
  height: 150px!important;
    width: 100%;
    object-fit: contain;
}

.summary{
  border: 1px solid #ccc;
}

.a-divider-inner{
  height: 44px;
  margin-bottom: -18px;
  background: -webkit-linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent);
  background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent);
  z-index: 0;
  zoom: 1;
}

.a-divider-inner:after{
  display: block;
  width: 100%;
  height: 44px;
  background-color: transparent;
  background: -webkit-linear-gradient(to right,#fff,rgba(255,255,255,0),#fff);
  background: linear-gradient(to right,#fff,rgba(255,255,255,0),#fff);
  z-index: 1;
  content: "";
}

.no_items_box{
  max-width: 500px;
    margin: auto;
}

/* ============== Mobile Side Nav start =======================*/


.sideMob .MuiDrawer-paper{
  width: 250px;
  padding: 1rem;
  background: #343434;
} 

.userDetails img{
  width: 30px;
  height: 30px;
}

.userDetails div h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.userDetails div h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.userDetails button{
  padding: 0;
  font-size: 11px;
}


/* ============== Mobile Side Nav End ========================= */

.orders-section .products{
  border-bottom: 2px dashed #EC6A23;
}

.orders-section .products:last-of-type{
  border-bottom: none;
}

.orders-section,.order_details{
  border: 2px solid #888;
}

.strip strong{
  font-size: 16px;
  z-index: 9;
}

.strip:after{
  content: '';
  position: absolute;
  top: 0;
  left: -16px;
  background-color: #eea789;
  width: calc(100% + 32px);
  height: 100%;
}

.custom_drop.profile a{
  background-color: transparent!important;
}

.owl-dots{
  background-color: #888;
  height: 2px;
  border-radius: 5px;
  width: 150px;
  display: flex;
  position: relative;
  margin:auto
}

.owl-dots:before,
.owl-dots:after{
  content: '';
  position: absolute;
  top: 0;
  background-image: url(../images/next.png);
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 10px;
  bottom: 0;
  margin:auto;
}

.flag{
  width: 30px;
}

.owl-dots:before{
  left: -20px;
  transform: rotate(-180deg);
}
.owl-dots:after{
  right: -20px;
  
}

.owl-dots .owl-dot{
 
  height: 5px;
  width: 33.3%;
  display: inline;
  
}
.owl-dots .owl-dot.active{
  background-color: #EC6A23;
  height: 5px;
  width: 33.3%;
  display: inline;
  margin-top: -2px;
  border-radius: 10px;
}

.sizeBox{
  border: 1px solid #ccc;
  width: 35px;
}

.sizeBox label{
  width: 100%;
    margin: 0;
    font-size: 11px;
    text-align: center;
}

.sizeBox label:first-child{
  border-bottom: 1px solid #ccc;
}

.small_list{
  max-height: 200px!important;
}

.sticky_header {
  position: sticky;
  top:0;
  z-index: 99;
}

/* ======================Order Start========================== */
header {
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #AAAAAA;
}

#logo {
  float: left;
  margin-top: 8px;
}

#logo img {
  height: 110px;
}

#company {
  float: right;
  text-align: right;
}

.text-theme{
  color: #f36f21!important;
}

#details {
  margin-bottom: 10px;
}

#client {
  padding-left: 6px;
  border-left: 6px solid #000;
  float: left;
}

#client .to {
  color: #777777;
}

h2.name {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0;
}

#invoice {
  float: right;
  text-align: right;
}

#invoice h1 {
  color: #000;
  font-size: 2.4em;
  line-height: 1em;
  font-weight: normal;
  margin: 0  0 10px 0;
}

#invoice .date {
  font-size: 1.1em;
  color: #777777;
}

table.orderInvoice {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

table.orderInvoice th{
  padding: 20px;
  background: #EEEEEE;
  text-align: center;
  border-bottom: 1px solid #FFFFFF;
  width: auto;
  max-width: 500px;
}

main{
  height: auto;
  position: relative;
  min-height: auto;
}

main .pageFooter{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

table.orderInvoice td { 
  padding: 10px 20px;
  background: #EEEEEE;
  text-align: center;
  border-bottom: 1px solid #FFFFFF;
  width: auto;
  max-width: 500px;
}

table.orderInvoice th {
  white-space: nowrap;        
  font-weight: normal;
}

table.orderInvoice td {
  text-align: right;
}

table.orderInvoice td h3{
  color: #000;
  font-size: 1.2em;
  font-weight: normal;
  margin: 0 0 0.2em 0;
}

table.orderInvoice .no {
  color: #FFFFFF;
  font-size: 1.6em;
  background: #000;
}

table.orderInvoice .desc {
  text-align: left;
}

table.orderInvoice .unit {
  background: #DDDDDD;
}

table.orderInvoice .qty {
}

table.orderInvoice .total {
  background: #000;
  color: #FFFFFF;
}

table.orderInvoice td.unit,
table.orderInvoice td.qty,
table.orderInvoice td.total {
  font-size: 1.2em;
}

table.orderInvoice tbody tr:last-child td {
  border: none;
}

table.orderInvoice tfoot td {
  padding: 10px 20px;
  background: #FFFFFF;
  border-bottom: none;
  font-size: 1em;
  white-space: nowrap; 
  border-top: 1px solid #AAAAAA; 
}

table.orderInvoice tfoot tr:first-child td {
  border-top: none; 
}

table.orderInvoice tfoot tr:last-child td {
  color: #000;
  font-size: 1.4em;
  border-top: 1px solid #000; 

}

table.orderInvoice tfoot tr td:first-child {
  border: none;
}

#notices{
  padding-left: 6px;
  border-left: 6px solid #000;  
}

#notices .notice {
  font-size: 1.2em;
}

/* footer {
  color: #777777;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #AAAAAA;
  padding: 8px 0;
  text-align: center;
} */

.bold{
  font-weight: bold;
}

.stamp{
  float: right;
}

.mb-20{
  margin-bottom: 20px;
}

.Amount{
margin-bottom: 20px;
    text-align: center;
   
    font-size: larger;
    color: red;
    font-weight: bold; 
}
.ml-10{
    margin-left:10px;
}

.width-50{
  width: 50% !important;
}

.width-50px{
  width: 50px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* =========================Order End ============================= */

.best-price{display: table;
  background: #6d6e71;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff !important;
  text-transform: capitalize;
  font-weight: 600;
  float: right;
  font-size: 13px;}
.best-offer{display:none; top:27px}
.best-offer .table{margin-bottom:0px;}
.best-offer .table tbody tr:hover td{background:#f5f5f5;}
.best-offer .table tbody tr.selected{background:#ccc;}

.best-price:hover .best-offer{display: block !important;
  position: absolute;
  z-index: 999;
  background: #fff;
  width: 100%;
  right: 0px;margin-top: 5px;
  box-shadow: 2px 2px 10px #666;}

  
.best-price-cart{display: table;
  background: #6d6e71;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff !important;
  text-transform: capitalize;
  font-weight: 600;
  float: right;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none!important;
}
.best-offer-cart{display:none; top:40px}
.best-offer-cart .table{margin-bottom:0px;}
.best-offer-cart .table tbody tr:hover td{background:#f5f5f5;}
.best-offer-cart .table tbody tr.selected{background:#ccc;}

.best-price-cart:hover .best-offer-cart{display: block !important;
  position: absolute;
  z-index: 999;
  background: #fff; 
  width: 100%;
  right: 0px;margin-top: 5px;
  box-shadow: 2px 2px 10px #666;}

  table.table thead.thead-dark {
    background-color: #666;
    color: #fff;
    font-weight: 600;
}
.total_gender {
  background: #000;
  width: 40px;
  height: 36px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  /* text-align: center; */
  display: flex!important;
  align-items: center;
  justify-content: center;
}
/* Invoice Print CSs */
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  font-size: 16px;
  line-height: 24px;
  /* font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; */
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
  line-height: 20px;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

/* .invoice-box table tr.top table td {
  padding-bottom: 20px;
} */

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

/* .invoice-box table tr.information table td {
  padding-bottom: 20px;
} */

.invoice-box table tr.information {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td{
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
      width: 100%;
      display: block;
      text-align: center;
  }
  
  .invoice-box table tr.information table td {
      width: 100%;
      display: block;
      text-align: center;
  }
}

.item_head td:first-child{
  width: 30px;
}

.invoice-box table tr.item_head td:nth-child(2) {
  width: 260px;
  text-align: left;
}

.invoice-box table tr.item_head td:nth-child(3) {
  width: 130px;
  text-align: right;
}

/** RTL **/
.rtl {
  direction: rtl;
  /* font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; */
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
.img-30{
  width: 30px !important;
}

.navbar-toggler:after{
  content: '';
  background-image: url(../images/menu.png);
  position: absolute;
  top: 17%;
  bottom: 0;
  width: 28px;
  height: 28px;
  background-size: contain;
  left: 30%;
  background-repeat: no-repeat;
}

.navbar-toggler{
  position: relative;
}

.sizeInput .MuiInputLabel-outlined{
  transform: translate(6px, 12px) scale(1);
}





.sizeInput label{
 font-size: 12px;
}

.orderDetails{
  position: absolute;
    bottom: 0%;
    left: 0;
    right: 0;
    margin: auto;
    width: 17%;
    padding: 1rem;
}


.orderDetails span{
  font-size: 11px;
}

.orderDetails div span{
  width: 120px;
}

.cancelContainer img{
  height: 350px;
  object-fit: contain;
}
.w-85{
  width: 85%!important;
}

.step_count{
  position: relative;
}

.step_count span{
    width: 50px;
    height: 50px;
    border: 4px solid #f5f5f5;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 22px;
    margin: auto;
    position: relative;
    background-color: #fff;
    margin-bottom: 30px;
}

.step_count::before{
  content: '';
  position: absolute;
  bottom: -100%;
  width: 2px;
  height: 150px;
  background-color: #000;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(0deg);
}

.howitworks_main .step_sections_.firstChild .step_count::before{
  content: '';
  position: absolute;
  bottom: -100%;
  width: 2px;
  height: 75px;
  background-color: #000;
  left: 0;
  right: 0;
  margin: auto;
}

.howitworks_main .step_sections_ .step_image p{
  color:#85878b;
  font-size: 14px;
}

.step_image h3{
  font-size: 22px;
  font-weight: 600;
}

.sizeInput .MuiInputBase-root.Mui-disabled{
  background: rgb(204 204 204 / 48%)!important;
}




@font-face {
  font-family: "forte";
  src: url(../fonts/FORTE.TTF);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gabriola";
  src: url(../fonts/gabriola.TTF);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Freestyle script";
  src: url(../fonts/FREESCPT.TTF);
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Gabriola";
  src: url(../Fonts/gabriola.TTF);
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Lucida Handwriting";
  src: url(../fonts/lhandw.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lucida Sans";
  src: url(../fonts/lsans.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Rockwell";
  src: url(../fonts/ROCK.TTF);
  font-weight: normal;
  font-style: normal;
}